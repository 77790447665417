import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Layout from "../../../components/layout"
import SEO from "../../../components/seo"
import BigHeaderVideo from "../../../components/header/bigHeader/bigHeaderVideo"
import "../../../components/layout/layout.css"
import "../../../components/devicon/devicon.css"
import logo from "../../../../static/logos/logo-wsg.svg"
import ogimage from "../../../images/projekte/wsg-gb-2021/wsg-gb-2021-01.jpg"
import Kontakt from "../../../components/kontakt"
import Navigation from '../../../components/projekte/navigation'
import {AnchorLink} from "gatsby-plugin-anchor-links"
import ThumbleisteSwiper from "../../../components/thumbleisteSwiper"
import Liste from "../../../components/liste"
import ListenModule from "../../../components/listenModule"
import myLocalVid from '../../../images/projekte/wsg-gb-2021/WSG-2021-Header_V1_komp.mp4'
import * as projekteStyles from "../projekte.module.css"
import Specs from "../../../components/specs"


const WSGGeschaeftsbericht2021 = function (props, ref) {

    const data = useStaticQuery(graphql`
        query WSGGeschaeftsbericht2021Query {
            heropic: file(relativePath: { eq: "images/projekte/wsg-gb-2021/wsg-gb-2021-01.jpg" }) {
                childImageSharp {
                    gatsbyImageData(          
                      placeholder: BLURRED,
                      layout: FULL_WIDTH,
                      aspectRatio: 1.7
                    )
                  }
            }

            ogimage: file(relativePath: { eq: "images/projekte/wsg-gb-2021/wsg-gb-2021-01.jpg" }) {
                childImageSharp {
                    fixed(height: 400) {
                        src
                        height
                        width
                    }
                }
            }

            allFile(filter: {extension: {regex: "/(jpg|png)/"}, relativeDirectory: {eq: "images/projekte/wsg-gb-2021"}} sort: {fields: name}) {
                edges {
                  node {
                    id
                    childImageSharp {
                        picscropped:gatsbyImageData(placeholder: DOMINANT_COLOR, aspectRatio: 1, layout: FULL_WIDTH, formats: [WEBP], transformOptions: {rotate: 0, cropFocus: CENTER})
                        pics:gatsbyImageData(placeholder: DOMINANT_COLOR, layout: FULL_WIDTH, formats: [WEBP])
                      }
                  }
                }
              }
        }
    `)
    const specsData = [
        { title: "Eckdaten", objects: [logo, "Digitaler Geschäftsbericht", "https://gb-2021.wsg-wohnen.de/"] },
        { title: "Leistungen", objects: ["konzept", "design", "programmierung"] },
        {
          title: "Toolchain",
          objects: ["sketch", "html", "css", "js", "wordpress"],
        },
      ]
  
    return (
        <Layout>
            <SEO
                title="WSG Geschäftsbericht 2021"
                description="Q:marketing realisiert digitalen Geschäftsbericht für die WSG Wohnungs- und Siedlungs-GmbH"
                image={data?.ogimage?.childImageSharp?.fixed}
            />
            {/* {data.heropic.childImageSharp.gatsbyImageData} */}
            <BigHeaderVideo mode="textFirst" className={`${projekteStyles.child}`} bgVideo={myLocalVid} videoMode="local" posterImage="">                
                <div className="row py-5 py-md-0">
                    <div className="col-12 col-md-5">
                        <img src={logo} className="h-40px" alt=""/>
                        <h1>wsg Geschäftsbericht 2021</h1>
                        <p className="introtext whitetextshadow">In diesem Jahr berichten die Mieter über ihr Zuhause und die langjährige Verbindung zur wsg.</p>
                        <AnchorLink to="/projekte/corporate-communications/wsg-geschaeftsbericht-2021/#thumbleiste" title="↓ Mehr Erfahren" className="q-btn q-btn-primary" />
                    </div>
                    <div className="col-7"></div>
                </div>
            </BigHeaderVideo>
                               
            {/* Bilderstrecke Thumbs */}   
            <ThumbleisteSwiper picdata={data.allFile} bgCol="#EFEFE6"/>    


            {/* Reasons why */}
            <ListenModule headline="Reasons why">
                <Liste
                    items={[
                        "Zeitgemäße digitale Darstellung des Geschäftsberichts.",
                        "Authentische und sympathische Darstellung durch Interviews mit den Mietern der wsg.",
                        "Erhöhung der Reichweite im Vergleich zur gedruckten Variante."                      
                    ]}
                />
            </ListenModule>
            
            <Specs data={specsData} />                 
            
            {/* navigation */}            
            <Navigation location={props.location} />

            {/* kontakt */}            
            <Kontakt ansprechpartner="christian-frieling"/>

        </Layout>
    )
}

export default WSGGeschaeftsbericht2021